import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import processBg from '../img/bg/process-bg.jpg';

import Layout from '../components/Layout';
import FeatureBG from '../img/bg/features-bg.jpg';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

export const IndexPageTemplate = ({ banner, cash_offer, video_section, process_section, features, testimonials_section }) => {
  return (
    <main>
      <BannerSection banner={banner} cash_offer={cash_offer} />
      <VideoSection video_section={video_section} />
      <ProcessSection process_section={process_section} />
      <FeaturesSection features={features} />
      <TestimonialsSection testimonials_section={testimonials_section} />
    </main>
  );
};

const BannerSection = ({ banner, cash_offer }) => {
  return (
    <section className="relative bg-gray-50 overflow-hidden">
      <div className="block absolute inset-y-0 h-full w-full">
        <PreviewCompatibleImage imageInfo={{
          image: banner.image,
          childImageSharp: banner.image.childImageSharp,
          alt: "Open Land Investments Banner Background Image",
          classes: "h-full w-full object-cover opacity-75"
        }} />
      </div>

      <div className="relative pt-6 pb-2 md:pb-20">
        <div className="mt-10 mx-auto max-w-6xl px-1 md:px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
          <div className="text-center">
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-5xl">
              { banner.heading }
            </h2>
            <p className="mt-3 max-w-md mx-auto font-medium text-base text-gray-800 sm:text-xl md:mt-5 md:text-2xl md:max-w-3xl">
              { banner.subheading }
            </p>
            <div className="max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <a href="#" className="group w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-600 hover:text-gray-500 focus:outline-none transition duration-200 ease-in-out md:py-4 md:text-xl md:px-10">
                Scroll down to get started
                <svg className="h-6 w-6 text-gray-500 group-hover:text-gray-400 ml-1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M19 9l-7 7-7-7"></path>
                </svg>
              </a>            
            </div>

            <CashOfferSection cash_offer={cash_offer} />
          </div>
        </div>
      </div>
    </section>
  );
};

const CashOfferSection = ({ cash_offer: { heading, cta_heading, about, benefits } }) => {
  return (
    <div className="relative text-left overflow-hidden rounded-md md:rounded-lg shadow-2xl" id="cash-offer">
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-6">
        <div className="bg-gray-50 bg-opacity-95 py-8 md:py-16 px-4 sm:px-6 lg:col-span-3 lg:px-8 lg:py-20 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl px-2 md:px-0 leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
              { heading }
            </h2>
            <div className="mt-6 text-base leading-6 text-gray-500">
              <ul>
                { benefits.map((benefit, index) => {
                  return (
                    <li className="mt-4" key={index}>
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center">
                            <svg className="h-10 w-10" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                              <path d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="ml-3">
                          {/* <h5 className="text-lg leading-6 font-medium text-gray-900">Competitive exchange rates</h5> */}
                          <p className="mt-2 text-xl leading-8 text-gray-500">
                            { benefit.text }
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                }) }
              </ul>
            </div>

            <p className="mt-8 text-lg leading-6 text-gray-500">
              { about.description }
            </p>
            <dl className="mt-6 text-base leading-6 text-gray-500">              
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <svg className="flex-shrink-0 h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <span className="ml-3">
                    { about.phone }
                  </span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">
                  Email
                </dt>
                <dd className="flex">
                  <svg className="flex-shrink-0 h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <span className="ml-3">
                    { about.email }
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="bg-white bg-opacity-95 py-8 pb-12 md:py-16 px-4 sm:px-6 lg:col-span-3 lg:py-20 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <h4 className="mb-5 text-xl leading-8 font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
              { cta_heading }
            </h4>
            <form action="#" method="POST" className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="full_name" className="sr-only">
                  Full name
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">                    
                    <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                  </div>
                  <input required name="fullname" id="full_name" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Full name" />
                </div>
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone Number
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">                    
                    <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                  </div>
                  <input required name="phone" id="phone" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Phone" />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"/>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"/>
                    </svg>
                  </div>
                  <input required name="email" id="email" type="email" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Email" />
                </div>
              </div>
              <div>
                <label htmlFor="county" className="sr-only">
                  County
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                  </div>
                  <input required name="field[2]" id="county" type="text" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="County" />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Parcel Number
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                  </div>
                  <input required name="field[3]" id="parcel_number" type="text" className="form-input block w-full py-3 px-4 pl-10 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Parcel Number" />
                </div>
              </div>
              <div className="">
                <span className="w-full inline-flex rounded-md shadow-sm">
                  <button type="submit" className="w-full inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                    Get my cash offer
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};

const VideoSection = ({ video_section: { heading, subheading, youtube_url }}) => {
  return (
    <section className="py-16 pb-0 bg-gray-100 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative lg:grid lg:grid-cols-2 lg:gap-16 lg:items-center">
          <div className="relative">
            <h4 className="text-3xl leading-8 font-extrabold text-green-600 tracking-tight sm:text-4xl sm:leading-10">
              { heading }
            </h4>
            <p className="mt-6 text-lg sm:text-xl leading-7 text-gray-500">
              { subheading }
            </p>
            <button className="mt-8 px-12 py-3 text-white sm:text-lg font-medium rounded-md shadow-sm bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
              <Link to='/process'>
                Learn More
              </Link>              
            </button>        
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0 overflow-hidden md:rounded-lg md:shadow-2xl">            
            <iframe className="w-full h-96" width="560" height="315" src={youtube_url.includes("/embed/") ? youtube_url : `https://www.youtube.com/embed/${youtube_url.split('youtube.com/watch?v=')[1]}`} frameborder="0" allow="accelerometer; encrypted-media; gyroscope;" allowfullscreen></iframe>
          </div>
        </div>

        <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>        
      </div>
    </section>
  );
};

const ProcessSection = ({ process_section: { heading, steps, cta } }) => {
  return (
    <div className="">
      <section className="relative sm:py-12 bg-white">
        <div className="absolute inset-y-0 h-full w-full">
          <img className="h-full w-full object-cover" src={processBg} alt="Open Land Investments Here's Our Process Background"/>
        </div>

        <div className="relative max-w-screen-xl bg-white bg-opacity-85 mx-auto px-4 sm:px-6 lg:px-8 py-8 lg:py-16 lg:pb-24 sm:rounded-lg overflow-hidden">
          <div className="text-center">
            <h3 className="mt-2 mb-6 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-10">
              {heading}
            </h3>
            <hr className="mx-auto max-w-sm border border-b-1 border-gray-200" />
            {/* <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
              Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.
            </p> */}
          </div>

          <div className="mt-12">
            <ul className="max-w-2xl mx-auto md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10">
              {steps.map((step, idx) => {            
                return (
                  <li className="mt-10 md:mt-0 animate-fade-in-bottom" key={idx}>
                    <div className="flex flex-col items-center sm:flex-row sm:items-start">
                      <div className="mb-3 sm:mb-0">
                        {step.icon.childImageSharp
                          ? <Img fixed={step.icon.childImageSharp.fixed} alt={`Open Land Investments ${step.heading} Icon`} />
                          : <img src={step.icon} alt={`Open Land Investments ${step.heading} Icon`} />
                        }
                      </div>
                      <div className="mb-2 sm:mb-0 ml-4">
                        <h4 className="mb-2 text-xl lg:text-2xl leading-6 uppercase font-extrabold text-green-500">
                          {step.heading}
                        </h4>
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          {step.subheading}
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          {step.description}
                        </p>
                      </div>
                    </div>
                  </li>
                );
              })}            
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-green-500">
        <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
            {cta.heading}
            <br />
            <span className="text-2xl sm:text-3xl text-green-200">
              {cta.subheading}
            </span>
          </h2>

          <div className="mt-8 flex lg:flex-shrink-0 w-full sm:w-80 lg:mt-0">
            <div className="sm:ml-3 w-full inline-flex rounded-md shadow">
              <Link to="/#cash-offer" className="w-full inline-flex items-center justify-center px-5 py-3 sm:py-4 border border-transparent text-base sm:text-lg leading-6 font-medium rounded-md text-green-600 bg-white hover:text-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                Get my cash offer
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

const FeaturesSection = ({ features }) => {
  return (
    <section class="relative py-12 lg:py-24 bg-white">
      <div className="block absolute inset-0 h-full w-full">
        <img className="h-full w-full object-cover opacity-75" src={FeatureBG} alt="Open Land Investments Features Background"/>
      </div>

      <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center">
          <p class="text-base leading-6 text-green-500 font-semibold tracking-wide uppercase">
            Features
          </p>
          <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            A better way to sell your land
          </h3>
          {/* <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.
          </p> */}
        </div>

        <div class="mt-10 md:mt-12">
          <ul class="grid sm:grid-cols-2 lg:grid-cols-3 md:gap-x-8 gap-y-5 md:gap-y-10">
            {features.map(({ heading, subheading, icon}, index) => {
              return (
                <li key={index} className="px-6 py-8 bg-gray-50 bg-opacity-85 border border-solid border-gray-100 rounded-md shadow-md animate-fade-left">
                  <div class="flex flex-col">
                    <div class="flex-shrink-0">
                      <div class="flex items-center justify-center h-12 w-12">                        
                        {icon.childImageSharp
                          ? <Img fixed={icon.childImageSharp.fixed} alt={`Open Land Investments Feature Icon ${index}`} />
                          : <img src={icon} alt={`Open Land Investments Feature Icon ${index}`} />
                        }
                      </div>
                    </div>
                    <div class="mt-5">
                      <h4 class="text-lg leading-6 font-bold text-gray-900">
                        { heading }
                      </h4>
                      <p class="mt-2 text-base leading-6 text-gray-600">
                        { subheading }
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}                      
          </ul>
        </div>
      </div>
    </section>
  );
};

const TestimonialsSection = ({ testimonials_section: { heading, subheading, testimonials } }) => {
  return (
    <section class="py-12 lg:py-24 bg-gray-50">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center">
          {/* <p class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Transactions</p> */}
          <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            { heading }
          </h3>
          <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            { subheading }
          </p>
        </div>

        <div class="mt-12">
          <ul class="grid sm:grid-cols-2 lg:grid-cols-3 md:gap-x-6 md:gap-y-10">
            {testimonials.map((testimonial, index) => {
              return (
                <li key={index} class="relative max-w-screen-xl mx-auto mt-12 md:mt-0 px-4 sm:px-6 lg:px-8">          
                  <div class="relative">
                    <div class="md:flex-shrink-0">
                      {/* <PreviewCompatibleImage imageInfo={{
                        image: testimonial.avatar,
                        childImageSharp: testimonial.avatar.childImageSharp,
                        classes: "mx-auto h-24 w-24 object-cover border-2 border-solid border-green-600 shadow-lg rounded-full",
                        alt: `Testimonial Avatar`
                      }} /> */}
                      {/* <img class="mx-auto h-24 w-24 shadow-lg rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> */}
                    </div>

                    <blockquote class="mt-6">
                      <div class="max-w-3xl mx-auto text-left md:text-center text-xl leading-8 font-regular text-gray-800">
                        <p>
                          &ldquo;{ testimonial.quote }&rdquo;
                        </p>
                      </div>
                      <footer class="mt-4 md:mt-8">
                        <div class="md:flex md:items-center md:justify-center">                          
                          <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:flex-col md:items-center">
                            <div class="text-base text-lg leading-6 font-medium text-gray-900">
                              { testimonial.name }
                            </div>
                            
                            <div class="text-base leading-6 font-medium text-gray-500">
                              { testimonial.descriptor }
                            </div>
                          </div>
                        </div>
                      </footer>
                    </blockquote>
                  </div>

                  <hr className="mt-5 w-24 mx-auto border border-b-2 border-green-600 opacity-75 md:hidden" />
                </li>
              );              
            })}                                                      
          </ul>
        </div>
      </div>
    </section>
  );
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        banner={frontmatter.banner}
        cash_offer={frontmatter.cash_offer}
        video_section={frontmatter.video_section}
        process_section={frontmatter.process_section}
        features={frontmatter.features}
        testimonials_section={frontmatter.testimonials_section}
      />
    </Layout>
  )
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        banner {
          heading
          subheading
          image {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cash_offer {
          heading
          cta_heading
          about {
            description
            phone
            email
          }
          benefits {
            text
          }
        }
        video_section {
          heading
          subheading
          youtube_url
        }
        process_section {
          heading
          steps {
            heading
            subheading
            description
            icon {
              childImageSharp {
                fixed(width: 65) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          cta {
            heading
            subheading
          }
        }
        features {
          icon {
            childImageSharp {
              fixed(width: 50) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          heading
          subheading
        }
        testimonials_section {
          heading
          subheading
          testimonials {
            quote
            name
            descriptor
            avatar {
              childImageSharp {
                fluid(maxWidth: 100, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }          
        }
      }
    }
  }
`;
